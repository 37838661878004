import './monthlyclosurecard.scss';
import { useRef, useState, useEffect } from 'react';
import { useToast } from '../../../config/hooks/useToast';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../../config/hooks/useCallApi';
import ButtonBody from '../../button/button';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../store/slices/property-unit/property-unit.interface';
import { useAppSelector } from '../../../store/hooks';
import { Radio, ConfigProvider, DatePicker } from 'antd';
import type { RadioChangeEvent } from 'antd';
import CloseIcon from '@material-ui/icons/Close';
import { PDFExport } from '@progress/kendo-react-pdf';
import BalanceCertificate from './certificates/balance';
import { ApiSinaderData } from '../../../config/service';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

interface Props {
    data: any;
    colorsDB: any;
    ecoequivalences: any;
}

const MonthlyClosureCard = ({ data, colorsDB, ecoequivalences }: Props) => {
    const { GetData } = useCallApi();
    const { preferences, userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                monthlyClosureCard: {
                    title,
                    subtitle,
                    sinader,
                    certificates,
                    billing,
                    downloadSinaderButton,
                    downloadButton,
                    billingButton,
                    months,
                    balanceTitle
                }
            }
        }
    } = lang;
    const [show, setShow] = useState<boolean>(false);
    const [showSinader, setShowSinader] = useState<boolean>(false);
    const [value, setValue] = useState<number>(2);
    const pdfExportComponent = useRef<PDFExport>(null);
    const [month, setMonth] = useState<string | null>(null);
    const [monthNum, setMonthNum] = useState<number>();
    const [year, setYear] = useState<number>(2024);
    const [defaultDate, setDefaultDate] = useState<any>();
    const [names, setNames] = useState<string[]>([]);
    const [dates, setDates] = useState<{ dateIni: string; dateFin: string }>({
        dateIni: '',
        dateFin: ''
    });

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const ChangeDate = date => {
        const formattedMonthNum = date.format('M');
        const formattedMonth = date.format('MM');
        const year = date.$y;
        setMonth(months[formattedMonthNum - 1]);
        setMonthNum(parseInt(formattedMonth));
        setYear(year);
        const defaultDate = dayjs(`${year}-${formattedMonth}-01`);
        setDefaultDate(defaultDate);
    };

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses son indexados desde 0
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const ChangeDateSinader = date => {
        const year = date.$y; // Obtener el año
        const month = date.format('MM'); // Obtener el mes en formato numérico

        // Construir fechas en formato 'yyyy-mm-dd'
        const formattedFirstDay = `${year}-${month}-01`; // Primer día del mes
        const lastDayOfMonth = new Date(year, month, 0); // Último día del mes
        const formattedLastDay = formatDate(lastDayOfMonth);

        // Establecer el estado
        setDates({
            dateIni: formattedFirstDay,
            dateFin: formattedLastDay
        });
    };

    const DownloadCertificate = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
            setShow(false);
        }
    };

    const obtainMonth = () => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();

        if (actualDay >= 13) {
            actualDate.setMonth(actualDate.getMonth() - 1);
        } else {
            actualDate.setMonth(actualDate.getMonth() - 2);
        }
        const numberMonth = actualDate.getMonth() + 1;
        const numberFormat = numberMonth.toString().padStart(2, '0');
        const monthResult = months[numberMonth - 1];
        const yearResult = actualDate.getFullYear();

        return { month: monthResult, year: yearResult, monthNum: numberFormat };
    };

    const handleButton = (number: number) => {
        if (selected.length + selectedUnits.length === 0 && number === 2) {
            const toastMessage =
                'Debes tener al menos 1 propiedad/unidad seleccionado';
            ConfigToast.text = toastMessage;
            ConfigToast.backColor = Colors.Warning;
            setConfigToast(ConfigToast);
        } else if (
            selected.length + selectedUnits.length !== 1 &&
            number === 1
        ) {
            const toastMessage =
                'Debes tener solo 1 propiedad/unidad seleccionado';
            ConfigToast.text = toastMessage;
            ConfigToast.backColor = Colors.Warning;
            setConfigToast(ConfigToast);
        } else {
            if (number === 1) {
                setShowSinader(true);
            } else {
                setShow(true);
            }
        }
    };

    const DownloadSinader = async () => {
        /* const actualDate = new Date(); */
        /* const actualDay = actualDate.getDate(); */
        /* const dateCard = new Date(actualDate); */
        /* if (actualDay > 13) { */
        /*     dateCard.setMonth(actualDate.getMonth() - 1); */
        /* } else { */
        /*     dateCard.setMonth(actualDate.getMonth() - 2); */
        /* } */
        /* dateCard.setDate(1); */
        /* const dateini = dateCard.toISOString().slice(0, 10); */
        /* dateCard.setMonth(dateCard.getMonth() + 1); */
        /* dateCard.setDate(0); */
        /* const datefin = dateCard.toISOString().slice(0, 10); */
        // Supongo que userData es un objeto que contiene un array llamado roles
        const gestorRole = userData.roles.find(role => role.gestorData);

        const data = {
            dateini: dates.dateIni,
            datefin: dates.dateFin,
            gestorname: gestorRole ? gestorRole.gestorData.name : null,
            propertyId: selected[0]?.id ?? null,
            unitId: selectedUnits[0]?.id ?? null,
            isForm: true
        };

        const response = await GetData(ApiSinaderData, HTTP_METHODS.POST, data);
        const {
            data: { filepathdownload }
        } = response;
        window.open(filepathdownload, '_blank');
    };

    /* const handleReady = (ready: boolean) => {
        setReady(ready);
    }; */

    useEffect(() => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();
        const dateCard = new Date(actualDate);

        if (actualDay > 10) {
            dateCard.setMonth(actualDate.getMonth() - 1);
        } else {
            dateCard.setMonth(actualDate.getMonth() - 2);
        }

        dateCard.setDate(1);
        const dateIni = dateCard.toISOString().slice(0, 10);

        dateCard.setMonth(dateCard.getMonth() + 1);
        dateCard.setDate(0);
        const dateFin = dateCard.toISOString().slice(0, 10);

        setDates({ dateIni, dateFin });
    }, []);

    useEffect(() => {
        const today = dayjs();
        let date;

        if (today.date() <= 10) {
            // Si es el día 10 o antes, restamos 2 meses
            date = today.subtract(2, 'month');
        } else {
            // Si es después del día 10, restamos 1 mes
            date = today.subtract(1, 'month');
        }

        const formattedMonthNum = parseInt(date.month()) + 1;
        const formattedMonth = date.format('MM');
        setMonth(months[formattedMonthNum - 1]);
        setMonthNum(parseInt(formattedMonth));
        setYear(date.year());
        setDefaultDate(date);
    }, []);

    useEffect(() => {
        // Crear un mapa para las unidades agrupadas por dirección
        const addressMap = new Map();

        // Agregar las selectedUnits al mapa por dirección
        selectedUnits.forEach(item => {
            const address = item.property?.address || '';
            const unitName = item.name;

            if (address) {
                if (!addressMap.has(address)) {
                    addressMap.set(address, []);
                }
                addressMap.get(address).push(unitName);
            }
        });

        // Crear una lista de nombres agrupados por dirección
        const selectedUnitsNames = Array.from(addressMap.entries()).map(
            ([address, unitNames]) => {
                return `${unitNames.join(', ')} | ${address}`;
            }
        );

        // Agregar las selected normales sin modificar
        const selectedNames = selected.map(item => {
            const address = item.address ? ` | ${item.address}` : '';
            return `${item.name}${address}`;
        });

        // Combinar todos los nombres y establecer el estado
        const names = [...selectedNames, ...selectedUnitsNames];
        setNames(names);
    }, [selected, selectedUnits]);

    return (
        <>
            <div className={`monthly-card`}>
                <ToastElement ref={toastManagerRef} />
                <div className='monthly-card__titulo-card title'>{title}</div>
                <div className='monthly-card__subtitulo-card subtitle'>
                    {subtitle}: {obtainMonth().month} {obtainMonth().year}
                </div>
                <div className='monthly-card__content'>
                    <div className='monthly-card__content__sub-section '>
                        <p className='monthly-card__content__sub-section__title subtitle'>
                            {sinader} SINADER
                        </p>
                        <button
                            className='button button-body--sinader'
                            onClick={() => handleButton(1)}
                        >
                            {downloadSinaderButton}
                        </button>
                    </div>
                    <div className='monthly-card__content__sub-section'>
                        <p className='monthly-card__content__sub-section__title subtitle'>
                            {certificates}
                        </p>
                        <button
                            className='button button-body--sinader'
                            onClick={() => handleButton(2)}
                        >
                            {downloadButton}
                        </button>
                        {/* <ButtonBody
                        able='true'
                        kind='button button-body--sinader'
                        text={downloadButton}
                    ></ButtonBody> */}
                    </div>
                    <div className='monthly-card__content__sub-section  disabled'>
                        <p className='monthly-card__content__sub-section__title subtitle'>
                            {billing}
                        </p>
                        <ButtonBody
                            able='true'
                            kind='button button-body--sinader'
                            text={billingButton}
                        ></ButtonBody>
                    </div>
                </div>
            </div>
            {show && (
                <div className='popUp'>
                    <div className='popUp__main'>
                        <div className='popUp__main__container'>
                            <div className='popUp__main__title title'>
                                <span> {title} </span>
                                <CloseIcon
                                    className='popUp__main__title__close'
                                    onClick={() => {
                                        setShow(false);
                                    }}
                                />
                                <hr />
                            </div>
                            <div className='popUp__main__text subtitle'>
                                <div className='popUp__main__text__date-picker'>
                                    <div className='popUp__main__text__date-picker__title'>
                                        Debe seleccionar 1 mes a continuación:
                                    </div>
                                    <ConfigProvider
                                        locale={esES}
                                        theme={{
                                            components: {
                                                DatePicker: {
                                                    colorLink: '#a2a2a3',
                                                    colorLinkActive: '#a2a2a3',
                                                    colorPrimary: '#a2a2a3',
                                                    colorPrimaryBorder:
                                                        '#a2a2a3',
                                                    colorPrimaryHover:
                                                        '#a2a2a3',
                                                    colorBgContainer: '#f3f2f5',
                                                    colorBorder: '#fff',
                                                    fontFamily: 'Roboto',
                                                    colorIcon: '#a2a2a3',
                                                    colorText: '#C4C3C5',
                                                    colorTextHeading: '#a2a2a3',
                                                    colorTextPlaceholder:
                                                        '#a2a2a3'
                                                }
                                            }
                                        }}
                                    >
                                        <DatePicker
                                            onChange={ChangeDate}
                                            picker='month'
                                            defaultValue={defaultDate}
                                        />
                                    </ConfigProvider>
                                </div>
                                <div className='popUp__main__text__radio-group'>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Radio: {
                                                    colorPrimary: '#A2A2A3',
                                                    colorBorder: '#A2A2A3',
                                                    colorPrimaryHover: '#A2A2A3'
                                                }
                                            }
                                        }}
                                    >
                                        <Radio.Group
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {/* <Radio value={0}>
                                                <label className='popUp__main__text__label subtitle'>
                                                    Certificado anual de gestión de residuos
                                                </label>
                                             </Radio> */}
                                            <Radio value={1}>
                                                <label className='popUp__main__text__label subtitle'>
                                                    {balanceTitle}
                                                </label>
                                            </Radio>
                                        </Radio.Group>
                                    </ConfigProvider>
                                </div>
                            </div>
                        </div>
                        <div className='popUp__main__buttons app-d-flex__center childs_uniform'>
                            <button
                                className={`button button-body--sinader ${
                                    value === 2 && 'disabled'
                                }`}
                                onClick={DownloadCertificate}
                            >
                                Descargar certificado
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showSinader && (
                <div className='popUp'>
                    <div className='popUp__main'>
                        <div className='popUp__main__container'>
                            <div className='popUp__main__title title'>
                                <span> {title} </span>
                                <CloseIcon
                                    className='popUp__main__title__close'
                                    onClick={() => {
                                        setShowSinader(false);
                                    }}
                                />
                                <hr />
                            </div>
                            <div className='popUp__main__text subtitle'>
                                <div className='popUp__main__text__date-picker'>
                                    <div className='popUp__main__text__date-picker__title'>
                                        Debe seleccionar 1 mes a continuación:
                                    </div>
                                    <ConfigProvider
                                        locale={esES}
                                        theme={{
                                            components: {
                                                DatePicker: {
                                                    colorLink: '#a2a2a3',
                                                    colorLinkActive: '#a2a2a3',
                                                    colorPrimary: '#a2a2a3',
                                                    colorPrimaryBorder:
                                                        '#a2a2a3',
                                                    colorPrimaryHover:
                                                        '#a2a2a3',
                                                    colorBgContainer: '#f3f2f5',
                                                    colorBorder: '#fff',
                                                    fontFamily: 'Roboto',
                                                    colorIcon: '#a2a2a3',
                                                    colorText: '#C4C3C5',
                                                    colorTextHeading: '#a2a2a3',
                                                    colorTextPlaceholder:
                                                        '#a2a2a3'
                                                }
                                            }
                                        }}
                                    >
                                        <DatePicker
                                            onChange={ChangeDateSinader}
                                            picker='month'
                                            defaultValue={defaultDate}
                                        />
                                    </ConfigProvider>
                                </div>
                                <div className='popUp__main__text__radio-group'></div>
                            </div>
                        </div>
                        <div className='popUp__main__buttons app-d-flex__center childs_uniform'>
                            <button
                                className={`button button-body--sinader`}
                                onClick={DownloadSinader}
                            >
                                Descargar planilla SINADER
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {value === 1 && (
                <BalanceCertificate
                    data={data}
                    colorsDB={colorsDB}
                    pdfExportRef={pdfExportComponent}
                    name={names}
                    propertyId={selected.map(property => property.id)}
                    propertyUnitId={selectedUnits.map(unit => unit.id)}
                    month={`${month}`}
                    monthNumber={`${monthNum}`}
                    year={year}
                    show={show}
                    ecoequivalences={ecoequivalences}
                />
            )}
        </>
    );
};

export default MonthlyClosureCard;
