//  <--COMPONENTS--> //
import { useEffect, useState } from 'react';
import EcoequivalencesCard from '../../components/dataCenterComponents/ecoequivalencesCard/ecoequivalencesCard';
import MonthlyClosureCard from '../../components/dataCenterComponents/monthlyClosureCard/monthlyclosurecard';
import BZeroIndexCard from '../../components/dataCenterComponents/bzeroIndexCard/bzeroindexcard';
import RankingCard from '../../components/dataCenterComponents/rankingCard/rankingCard';
import MonthlyEvolutionCard from '../../components/dataCenterComponents/monthlyEvolutionCard/monthlyEvolutionCard';
import GarbageControlCard from '../../components/dataCenterComponents/garbageControl/garbageControl';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
//  <--REDUX--> //
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PropertyUnitStoreInterface } from '../../store/slices/property-unit/property-unit.interface';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { setMaterialData } from '../../store/slices/material';
import { GetStorage, SaveStorage } from '../../config/utils/Storage';
import './data-center.scss';
import useWindowDimensions from '../../config/hooks/useWindowDimentions';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../config/hooks/useCallApi';
import {
    ApiUserDataByUser,
    ApiPropertyDataByPropertyGestor,
    ApiPropertyDataByPropertyUnit
} from '../../config/service';
import { GetBzeroIndex } from '../../config/utils/IdbFormatData';
import { initValues } from './data';
import { lang } from '../langs';
import { PropertyStoreInterface } from '../../store/slices/property/property.interface';

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

const HomePage = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { userData, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageDataCenter: { months }
        }
    } = lang;
    const dispatch = useAppDispatch();
    /* const [labels, setLabels] = useState<string[]>([]); */
    const [garbageData, setGarbageData] = useState<any>(initValues);
    const [colorsDB, setColorsDB] = useState({});
    const [dataApiledChart, setDataApiledChart] = useState<any>(initValues);
    const [dataApiled, setDataApiled] = useState<any>(initValues);
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const sessions = localStorage.getItem('sessions');
    const [img, setImg] = useState<string>('zero');
    const [ranking, setRanking] = useState<any[]>([]);
    const [ecoequivalences, setEcoequivalences] = useState<any[]>([]);
    const [bzeroindex, setBzeroindex] = useState<number>(0);
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const month = label.substring(4, 6); // label es 'yyyymm', así que solo extraemos los últimos 2 caracteres
            return `${months[parseInt(month) - 1].slice(0, 1)}`; // Obtén la primera letra del mes
        });
        setFormattedLabels(formatted);
    };

    const CallData = async () => {
        const colors = localStorage.getItem('colors');
        setColorsDB(colors ? JSON.parse(colors) : []);
        const storedData = localStorage.getItem('actual-data');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setDataApiledChart(parsedData.dataApiledChart || []);
            setGarbageData(parsedData.garbageData || []);
            setEcoequivalences(parsedData.ecoequivalences || []);
            setBzeroindex(parsedData.bzeroindex || 0);
            setRanking(parsedData.ranking || []);
        } else {
            const cardsData = await GetData(
                ApiUserDataByUser(userData.id),
                HTTP_METHODS.GET
            );

            const evolutionCardHistoric = cardsData.data.find(
                item => item.kind === 'evolution-card-historic'
            );
            if (evolutionCardHistoric?.data) {
                localStorage.setItem(
                    'evolution-card-historic',
                    JSON.stringify(evolutionCardHistoric)
                );
                const parsedData = JSON.parse(evolutionCardHistoric.data);
                const last12Labels = parsedData.labels.slice(-12);
                const last12Datasets = parsedData.datasets.map(dataset => ({
                    ...dataset,
                    data: dataset.data.slice(-12) // Tomamos los últimos 12 valores
                }));
                const filteredData = {
                    ...parsedData,
                    labels: last12Labels, // Actualizamos los labels
                    datasets: last12Datasets // Actualizamos los datasets con los últimos 12 valores
                };
                setDataApiledChart(filteredData);
                setDataApiled(parsedData);
                const materials = parsedData.datasets.map((item: any) => ({
                    name: item.id,
                    selected: true,
                    show: true
                }));
                dispatch(setMaterialData(materials));
                SaveStorage(
                    materials,
                    process.env.REACT_APP_PUBLIC_USER_MATERIALS
                );
            }
            const garbagecontrolCard = cardsData.data.find(
                item => item.kind === 'garbagecontrol-card'
            );
            if (garbagecontrolCard?.data) {
                localStorage.setItem(
                    'garbagecontrol-card',
                    JSON.stringify(garbagecontrolCard)
                );
                const parsedData = JSON.parse(garbagecontrolCard.data);
                const last12Labels = parsedData.labels.slice(-12);
                const last12Datasets = parsedData.datasets.map(dataset => ({
                    ...dataset,
                    data: dataset.data.slice(-12) // Tomamos los últimos 12 valores
                }));
                const filteredData = {
                    ...parsedData,
                    labels: last12Labels, // Actualizamos los labels
                    datasets: last12Datasets // Actualizamos los datasets con los últimos 12 valores
                };
                setGarbageData(filteredData);
            }
            const rankingCard = cardsData.data.find(
                item => item.kind === 'ranking-card'
            );
            if (rankingCard?.data) {
                localStorage.setItem(
                    'ranking-card',
                    JSON.stringify(rankingCard)
                );
                const rankingData = JSON.parse(rankingCard.data);
                setRanking(rankingData.ranking ? rankingData.ranking : []);
            }

            const ecoequivalencesCard = cardsData.data.find(
                item => item.kind === 'ecoequivalences-card'
            );
            if (ecoequivalencesCard?.data) {
                localStorage.setItem(
                    'ecoequivalences-card',
                    JSON.stringify(ecoequivalencesCard)
                );
                setEcoequivalences(JSON.parse(ecoequivalencesCard.data));
            }

            const bzeroindexCard = cardsData.data.find(
                item => item.kind === 'bzeroindex-card'
            );
            if (bzeroindexCard?.data) {
                localStorage.setItem(
                    'bzeroindex-card',
                    JSON.stringify(bzeroindexCard)
                );
                setBzeroindex(
                    parseFloat(bzeroindexCard.data.replace(',', '.'))
                );
            }
        }
    };

    const ObtainBZeroIndexes = dataFormatedApiled => {
        const dataBasura = dataFormatedApiled.datasets.filter(
            item => item.label === 'Basura'
        );
        const dataFinal = dataFormatedApiled.datasets.filter(
            item => item.label !== 'Índice BZero'
        );
        if (dataBasura.length > 0) {
            const indexesArray = new Array(dataBasura[0].data.length).fill(0);
            dataFinal.forEach(obj => {
                obj.data.forEach((value, index) => {
                    indexesArray[index] =
                        parseFloat(indexesArray[index]) + parseFloat(value);
                });
            });
            const sum = Array.from(
                { length: dataBasura[0].data.length },
                () => 0
            );
            dataBasura.forEach(item => {
                item.data.forEach((value, index) => {
                    sum[index] += parseFloat(value);
                });
            });
            sum.forEach((item, index) => {
                indexesArray[index] =
                    (item / parseFloat(indexesArray[index])) * 100;
            });
            const indexBZero = dataFormatedApiled.datasets.findIndex(
                item => item.label === 'Índice BZero'
            );
            if (indexBZero !== -1) {
                dataFormatedApiled.datasets[indexBZero].data = indexesArray;
            }
        }
        dataFormatedApiled.datasets.sort((a, b) => {
            if (a.label === 'Índice BZero') return -1; // 'Índice BZero' primero
            if (b.label === 'Índice BZero') return 1;
            if (a.label === 'Basura') return -1; // 'Basura' segundo
            if (b.label === 'Basura') return 1;
            // Si no es ni 'Índice BZero' ni 'Basura', ordena por 'kind'
            return a.kind.localeCompare(b.kind);
        });
        const last12Labels = dataFormatedApiled.labels.slice(-12);
        const last12Datasets = dataFormatedApiled.datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.slice(-12) // Tomamos los últimos 12 valores
        }));
        const filteredData = {
            ...dataFormatedApiled,
            labels: last12Labels, // Actualizamos los labels
            datasets: last12Datasets // Actualizamos los datasets con los últimos 12 valores
        };
        setDataApiledChart(filteredData);
        setDataApiled(dataFormatedApiled);
    };

    const handleButtonClick = () => {
        localStorage.setItem('sessions', '1');
        setImg('end');
    };

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        CallData();
    }, []);

    const resetDataApiledChart = () => {
        const evolutionCard = localStorage.getItem('evolution-card-historic');
        const parsedEvolutionCard = evolutionCard
            ? JSON.parse(evolutionCard)
            : [];
        const parsedEvolutionData = parsedEvolutionCard.data
            ? JSON.parse(parsedEvolutionCard.data)
            : initValues;
        const last12Labels = parsedEvolutionData.labels.slice(-12);
        const last12Datasets = parsedEvolutionData.datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.slice(-12) // Tomamos los últimos 12 valores
        }));
        const filteredData = {
            ...parsedEvolutionData,
            labels: last12Labels, // Actualizamos los labels
            datasets: last12Datasets // Actualizamos los datasets con los últimos 12 valores
        };
        setDataApiledChart(filteredData);
        setDataApiled(parsedEvolutionData);

        const garbagecontrolCard = localStorage.getItem('garbagecontrol-card');
        const parsedGarbagecontrolCard = garbagecontrolCard
            ? JSON.parse(garbagecontrolCard)
            : [];
        const parsedGarbagecontrolData = parsedGarbagecontrolCard.data
            ? JSON.parse(parsedGarbagecontrolCard.data)
            : initValues;
        const last12GarbagecontrolLabels =
            parsedGarbagecontrolData.labels.slice(-12);
        const last12GarbagecontrolDatasets =
            parsedGarbagecontrolData.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(-12) // Tomamos los últimos 12 valores
            }));
        const filteredGarbagecontrolData = {
            ...parsedGarbagecontrolData,
            labels: last12GarbagecontrolLabels, // Actualizamos los labels
            datasets: last12GarbagecontrolDatasets // Actualizamos los datasets con los últimos 12 valores
        };
        setGarbageData(filteredGarbagecontrolData);

        const ecoequivalencesCard = localStorage.getItem(
            'ecoequivalences-card'
        );
        const parsedEcoequivalencesCard = ecoequivalencesCard
            ? JSON.parse(ecoequivalencesCard)
            : [];
        const parsedEcoequivalencesData = parsedEcoequivalencesCard.data
            ? JSON.parse(parsedEcoequivalencesCard.data)
            : [];
        setEcoequivalences(parsedEcoequivalencesData);

        const bzeroindexCard = localStorage.getItem('bzeroindex-card');
        const parsedBzeroindexCard = bzeroindexCard
            ? JSON.parse(bzeroindexCard)
            : '';
        const parsedBzeroindexData = parsedBzeroindexCard.data
            ? parsedBzeroindexCard.data
            : '';
        setBzeroindex(parseFloat(parsedBzeroindexData?.replace(',', '.')));

        const rankingCard = localStorage.getItem('ranking-card');
        const parsedRankingCard = rankingCard ? JSON.parse(rankingCard) : [];
        const parsedRankingData = parsedRankingCard.data
            ? JSON.parse(parsedRankingCard.data)
            : [];
        setRanking(parsedRankingData.ranking);
    };

    useEffect(() => {
        const updateAllSelectedData = async () => {
            // Obtener datos de las propiedades seleccionadas
            const uniqueSelected = selected.filter(
                (property, index, self) =>
                    index === self.findIndex(p => p.id === property.id)
            );
            const propertyDataList = await Promise.all(
                uniqueSelected.map(async property => {
                    const propertyDataStr = localStorage.getItem(
                        `property-${property.id}`
                    );
                    let propertyData;

                    if (propertyDataStr) {
                        propertyData = JSON.parse(propertyDataStr);
                    } else {
                        let gestor: string | null = null;
                        for (const role of userData.roles) {
                            if (role.gestorData) {
                                gestor = role.gestorData.id;
                                break;
                            }
                        }
                        propertyData = await GetData(
                            ApiPropertyDataByPropertyGestor,
                            HTTP_METHODS.POST,
                            { property: property.id, gestor }
                        );
                        localStorage.setItem(
                            `property-${property.id}`,
                            JSON.stringify(propertyData)
                        );
                    }

                    return propertyData;
                })
            );
            const uniqueSelectedUnits = selectedUnits.filter(
                (property, index, self) =>
                    index === self.findIndex(p => p.id === property.id)
            );
            // Obtener datos de las unidades seleccionadas
            const propertyUnitDataList = await Promise.all(
                uniqueSelectedUnits.map(async unit => {
                    const unitDataStr = localStorage.getItem(
                        `property-unit-${unit.id}`
                    );
                    let unitData;

                    if (unitDataStr) {
                        unitData = JSON.parse(unitDataStr);
                    } else {
                        unitData = await GetData(
                            ApiPropertyDataByPropertyUnit(unit.id),
                            HTTP_METHODS.GET
                        );
                        localStorage.setItem(
                            `property-unit-${unit.id}`,
                            JSON.stringify(unitData)
                        );
                    }

                    return unitData;
                })
            );

            // Combinar datos de propiedades y unidades en una sola lista
            const allDataList = [...propertyDataList, ...propertyUnitDataList];

            // Acumulación de datos para actualizar los estados
            let accumulatedData: any = null;
            let accumulatedGarbageData: any = null;
            let accumulatedEcoequivalences: any = [];
            const accumulatedRanking: any = [];
            // Procesar datos combinados
            allDataList.forEach(data => {
                const evolutionCard = data.data.find(
                    item => item.kind === 'evolution-card-historic'
                );
                const garbagecontrolCard = data.data.find(
                    item => item.kind === 'garbagecontrol-card'
                );
                const ecoequivalencesCard = data.data.find(
                    item => item.kind === 'ecoequivalences-card'
                );
                const bzeroindexCard = data.data.find(
                    item => item.kind === 'bzeroindex-card'
                );
                const rankingCard = data.data.find(
                    item => item.kind === 'ranking-card'
                );

                if (
                    evolutionCard?.data &&
                    garbagecontrolCard?.data &&
                    ecoequivalencesCard?.data &&
                    bzeroindexCard?.data &&
                    rankingCard?.data
                ) {
                    const newData = JSON.parse(evolutionCard.data);

                    if (!accumulatedData) {
                        accumulatedData = newData;
                    } else {
                        // Crear un nuevo conjunto que combine ambos datasets, sin perder datos
                        const combinedDatasets: any = [];

                        // Procesar los datasets que están en accumulatedData
                        accumulatedData.datasets.forEach(accumulatedDataset => {
                            const correspondingNewDataset =
                                newData.datasets.find(
                                    ds => ds.id === accumulatedDataset.id
                                );

                            if (correspondingNewDataset) {
                                // Si existe en ambos, acumular los datos
                                const updatedDataset: any = {
                                    ...accumulatedDataset,
                                    data: accumulatedDataset.data.map(
                                        (value, index) => {
                                            const currentValue =
                                                parseFloat(value) || 0;
                                            const newValue =
                                                parseFloat(
                                                    correspondingNewDataset
                                                        .data[index]
                                                ) || 0;
                                            const summedValue =
                                                currentValue + newValue;

                                            // Redondea a 1 decimal
                                            return parseFloat(
                                                summedValue.toFixed(1)
                                            ).toString();
                                        }
                                    )
                                };
                                combinedDatasets.push(updatedDataset);
                            } else {
                                // Si solo existe en accumulatedData, lo agregamos tal cual
                                combinedDatasets.push(accumulatedDataset);
                            }
                        });

                        // Procesar los datasets que están en newData pero no en accumulatedData
                        newData.datasets.forEach(newDataset => {
                            const existingDataset =
                                accumulatedData.datasets.find(
                                    dataset => dataset.id === newDataset.id
                                );

                            if (!existingDataset) {
                                // Si solo existe en newData, lo agregamos tal cual
                                combinedDatasets.push(newDataset);
                            }
                        });

                        // Asignamos el conjunto combinado de datasets
                        accumulatedData.datasets = combinedDatasets;
                    }

                    const newGarbageData = JSON.parse(garbagecontrolCard.data);
                    if (!accumulatedGarbageData) {
                        accumulatedGarbageData = newGarbageData;
                    } else {
                        // Crear un nuevo conjunto que combine ambos datasets, sin perder datos
                        const combinedGarbageDatasets: any = [];

                        // Procesar los datasets que están en accumulatedData
                        accumulatedGarbageData.datasets.forEach(
                            accumulatedGarbageDataset => {
                                const correspondingGarbageNewDataset =
                                    newData.datasets.find(
                                        ds =>
                                            ds.id ===
                                            accumulatedGarbageDataset.id
                                    );

                                if (correspondingGarbageNewDataset) {
                                    // Si existe en ambos, acumular los datos
                                    const updatedGarbageDataset: any = {
                                        ...accumulatedGarbageDataset,
                                        data: accumulatedGarbageDataset.data.map(
                                            (value, index) => {
                                                const currentValue =
                                                    parseFloat(value) || 0;
                                                const newValue =
                                                    parseFloat(
                                                        correspondingGarbageNewDataset
                                                            .data[index]
                                                    ) || 0;
                                                const summedValue =
                                                    currentValue + newValue;

                                                // Redondea a 1 decimal
                                                return parseFloat(
                                                    summedValue.toFixed(1)
                                                ).toString();
                                            }
                                        )
                                    };
                                    combinedGarbageDatasets.push(
                                        updatedGarbageDataset
                                    );
                                } else {
                                    // Si solo existe en accumulatedData, lo agregamos tal cual
                                    combinedGarbageDatasets.push(
                                        accumulatedGarbageDataset
                                    );
                                }
                            }
                        );

                        // Procesar los datasets que están en newData pero no en accumulatedData
                        newGarbageData.datasets.forEach(newGarbageDataset => {
                            const existingGarbageDataset =
                                accumulatedGarbageData.datasets.find(
                                    dataset =>
                                        dataset.id === newGarbageDataset.id
                                );

                            if (!existingGarbageDataset) {
                                // Si solo existe en newData, lo agregamos tal cual
                                combinedGarbageDatasets.push(newGarbageDataset);
                            }
                        });

                        // Asignamos el conjunto combinado de datasets
                        accumulatedGarbageData.datasets =
                            combinedGarbageDatasets;
                    }

                    accumulatedEcoequivalences =
                        accumulatedEcoequivalences.length === 0
                            ? ecoequivalencesCard?.data &&
                              Object.keys(ecoequivalencesCard.data).length > 0
                                ? Array.isArray(
                                      JSON.parse(ecoequivalencesCard.data)
                                  ) // Verificamos si es un arreglo
                                    ? JSON.parse(ecoequivalencesCard.data)
                                    : accumulatedEcoequivalences // Si no es un arreglo, mantenemos el valor actual
                                : accumulatedEcoequivalences // Si data es un objeto vacío, mantener el valor actual
                            : accumulatedEcoequivalences.map(
                                  (equivalence: any) => {
                                      // Validar que ecoequivalencesCard.data no sea vacío y sea un arreglo
                                      if (
                                          ecoequivalencesCard?.data &&
                                          Object.keys(ecoequivalencesCard.data)
                                              .length > 0
                                      ) {
                                          const parsedData = JSON.parse(
                                              ecoequivalencesCard.data
                                          );

                                          if (Array.isArray(parsedData)) {
                                              // Aseguramos que parsedData sea un arreglo
                                              const correspondingEquivalence =
                                                  parsedData.find(
                                                      (newEq: any) =>
                                                          newEq.type ===
                                                          equivalence.type
                                                  );

                                              if (correspondingEquivalence) {
                                                  return {
                                                      ...equivalence,
                                                      total: equivalence.total.map(
                                                          (
                                                              total: number,
                                                              index: number
                                                          ) => {
                                                              // Sumamos los valores en las mismas posiciones y redondeamos a 2 decimales
                                                              const correspondingTotal =
                                                                  parseFloat(
                                                                      correspondingEquivalence
                                                                          .total[
                                                                          index
                                                                      ] || 0
                                                                  );
                                                              return (
                                                                  total +
                                                                  parseFloat(
                                                                      (
                                                                          Math.round(
                                                                              correspondingTotal *
                                                                                  100
                                                                          ) /
                                                                          100
                                                                      ).toFixed(
                                                                          2
                                                                      )
                                                                  )
                                                              );
                                                          }
                                                      ),
                                                      equivalent:
                                                          equivalence.equivalent.map(
                                                              (
                                                                  equiv: number,
                                                                  index: number
                                                              ) => {
                                                                  // Sumamos los equivalentes en las mismas posiciones y redondeamos a 2 decimales
                                                                  const correspondingEquivalent =
                                                                      parseFloat(
                                                                          correspondingEquivalence
                                                                              .equivalent[
                                                                              index
                                                                          ] || 0
                                                                      );
                                                                  return (
                                                                      equiv +
                                                                      parseFloat(
                                                                          (
                                                                              Math.round(
                                                                                  correspondingEquivalent *
                                                                                      100
                                                                              ) /
                                                                              100
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                      )
                                                                  );
                                                              }
                                                          )
                                                  };
                                              }
                                          }
                                      }

                                      return equivalence;
                                  }
                              );

                    if (rankingCard.data !== 'NaN') {
                        accumulatedRanking.push(JSON.parse(rankingCard.data));
                    }
                }
            });
            // Actualizar estados
            if (accumulatedData) {
                ObtainBZeroIndexes(accumulatedData);
                setGarbageData({
                    labels: accumulatedGarbageData?.labels.slice(-12), // Obtener los últimos 12 elementos de labels
                    datasets: accumulatedGarbageData?.datasets.map(dataset => ({
                        ...dataset,
                        data: dataset.data.slice(-12) // Obtener los últimos 12 elementos de cada dataset
                    }))
                });

                setEcoequivalences(accumulatedEcoequivalences);
                const bzeroindex = GetBzeroIndex(accumulatedData, 3);
                setBzeroindex(bzeroindex);
                setRanking(
                    accumulatedRanking.sort(
                        (a, b) => a.BZeroIndexMonth - b.BZeroIndexMonth
                    )
                );
            }
        };

        if (selected.length === 0 && selectedUnits.length === 0) {
            resetDataApiledChart();
        } else {
            updateAllSelectedData();
        }
    }, [selected, selectedUnits]);

    useEffect(() => {
        const updatedData = {
            dataApiledChart,
            garbageData,
            ecoequivalences,
            bzeroindex,
            ranking
        };
        localStorage.setItem('actual-data', JSON.stringify(updatedData));
    }, [dataApiledChart, ecoequivalences, bzeroindex, ranking]);

    useEffect(() => {
        formatLabelsShow(dataApiledChart.labels);
    }, [dataApiledChart]);

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='body'>
                {!isMobile ? (
                    <>
                        <div className='body__column-left'>
                            <MonthlyEvolutionCard
                                data={dataApiledChart}
                                formattedLabels={formattedLabels}
                            />
                            <EcoequivalencesCard data={ecoequivalences} />
                            {appSections.some(item =>
                                item.name.includes('Garbage Control')
                            ) && (
                                <GarbageControlCard
                                    data={garbageData}
                                    formattedLabels={formattedLabels}
                                />
                            )}
                        </div>
                        <div className='body__column-right'>
                            <RankingCard data={ranking} />
                            <BZeroIndexCard index={bzeroindex} />
                            <MonthlyClosureCard
                                data={dataApiled}
                                colorsDB={colorsDB}
                                ecoequivalences={ecoequivalences}
                            />
                            {LoadingData && <LoaderElement />}
                        </div>
                        {LoadingData && <LoaderElement />}
                    </>
                ) : (
                    <>
                        <MonthlyEvolutionCard
                            data={dataApiledChart}
                            formattedLabels={formattedLabels}
                        />
                        <EcoequivalencesCard data={ecoequivalences} />
                        {appSections.some(item =>
                            item.name.includes('Garbage Control')
                        ) && (
                            <GarbageControlCard
                                data={garbageData}
                                formattedLabels={formattedLabels}
                            />
                        )}
                        <RankingCard data={ranking} />
                        <BZeroIndexCard index={bzeroindex} />
                        <MonthlyClosureCard
                            data={dataApiled}
                            colorsDB={colorsDB}
                            ecoequivalences={ecoequivalences}
                        />
                    </>
                )}
                {LoadingData && <LoaderElement />}
            </div>
            {sessions === '0' && img !== 'end' && (
                <div className='onboarding'>
                    <div className={`onboarding__main`}>
                        {img === 'zero' && (
                            <div
                                className={`onboarding__main__background ${img}`}
                            >
                                <div
                                    className={`onboarding__main__background__btns`}
                                >
                                    <button
                                        className='onboarding__main__background__btns__omit subtitle'
                                        onClick={() => handleButtonClick()}
                                    >
                                        Omitir
                                    </button>
                                    <button
                                        className='button button-body--onboarding'
                                        onClick={() => setImg('first')}
                                    >
                                        <div className='button-body--onboarding__content'>
                                            <div className='button-body--onboarding__content__text'>
                                                Guía rápida de uso
                                            </div>
                                            <img
                                                className='button-body--onboarding__content__img'
                                                src='/svg-icons/Arrows.svg'
                                            />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        )}
                        {img === 'first' && (
                            <div
                                className={`onboarding__main__background ${img}`}
                            >
                                <div
                                    className={`onboarding__main__background__btns`}
                                >
                                    <button
                                        className='button button-body--onboardingPrev'
                                        onClick={() => setImg('zero')}
                                    >
                                        <div className='button-body--onboardingPrev__content'>
                                            <img
                                                className='button-body--onboardingPrev__content__img'
                                                src='/svg-icons/Arrows.svg'
                                            />
                                        </div>
                                    </button>
                                    <button
                                        className='button button-body--onboardingNext'
                                        onClick={() => setImg('second')}
                                    >
                                        <div className='button-body--onboardingNext__content'>
                                            <img
                                                className='button-body--onboardingNext__content__img'
                                                src='/svg-icons/Arrows.svg'
                                            />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        )}
                        {img === 'second' && (
                            <div
                                className={`onboarding__main__background ${
                                    !isMobile && img
                                }`}
                            >
                                {isMobile ? (
                                    <div
                                        className={`onboarding__main__background__content ${
                                            isMobile && img
                                        }`}
                                    >
                                        <div
                                            className={`onboarding__main__background__${
                                                isMobile && 'content__'
                                            }btns`}
                                        >
                                            <button
                                                className={`button button-body--onboardingPrev`}
                                                onClick={() => setImg('first')}
                                            >
                                                <div className='button-body--onboardingPrev__content'>
                                                    <img
                                                        className='button-body--onboardingPrev__content__img'
                                                        src='/svg-icons/Arrows.svg'
                                                    />
                                                </div>
                                            </button>
                                            <button
                                                className='button button-body--onboardingNext'
                                                onClick={() => setImg('third')}
                                            >
                                                <div className='button-body--onboardingNext__content'>
                                                    <img
                                                        className='button-body--onboardingNext__content__img'
                                                        src='/svg-icons/Arrows.svg'
                                                    />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className={`onboarding__main__background__btns`}
                                    >
                                        <button
                                            className='button button-body--onboardingPrev'
                                            onClick={() => setImg('first')}
                                        >
                                            <div className='button-body--onboardingPrev__content'>
                                                <img
                                                    className='button-body--onboardingPrev__content__img'
                                                    src='/svg-icons/Arrows.svg'
                                                />
                                            </div>
                                        </button>
                                        <button
                                            className='button button-body--onboardingNext'
                                            onClick={() => setImg('third')}
                                        >
                                            <div className='button-body--onboardingNext__content'>
                                                <img
                                                    className='button-body--onboardingNext__content__img'
                                                    src='/svg-icons/Arrows.svg'
                                                />
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        {img === 'third' && (
                            <div
                                className={`onboarding__main__background ${img}`}
                            >
                                <div
                                    className={`onboarding__main__background__btns`}
                                >
                                    {!isMobile && (
                                        <button
                                            className='button button-body--onboardingPrev'
                                            onClick={() => setImg('second')}
                                        >
                                            <div className='button-body--onboardingPrev__content'>
                                                <img
                                                    className='button-body--onboardingPrev__content__img'
                                                    src='/svg-icons/Arrows.svg'
                                                />
                                            </div>
                                        </button>
                                    )}
                                    <button
                                        className='button button-body--onboarding'
                                        onClick={() => handleButtonClick()}
                                    >
                                        <div className='button-body--onboarding__content'>
                                            <div className='button-body--onboarding__content__text'>
                                                Ir a PRYMA
                                            </div>
                                            <img
                                                className='button-body--onboarding__content__img'
                                                src='/svg-icons/Arrows.svg'
                                            />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default HomePage;
