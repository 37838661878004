/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Cascader, ConfigProvider } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import type { SelectProps } from 'antd';
import { Wrapper } from '../styles';
import { UserFormikInterface } from '../../interface';
import {
    deffaulltButtons,
    FormBuilderFields,
    FormPropsInterface
} from './form-builder.interface';
//  <--FORMS--> //
import { Formik } from 'formik';
import './form.scss';
//  eslint-disable-next-line @typescript-eslint/no-var-requires
const yup = require('yup');

const defaults = {
    autocomplete: 'off',
    required: true,
    buttons: deffaulltButtons
};
let CleanForm;

const FormBuilder = ({
    initialValues,
    formStructure,
    focusOn,
    buttons,
    styles,
    config,
    onSuccessCleanForm = true,
    unitsOnSelect,
    propertiesOnSelect
}: FormPropsInterface) => {
    const [ready, setReady] = useState<boolean>(false);
    const [sendForm, setSendForm] = useState<boolean>(false);
    const [newFormStructure, setNewFormStructure] = useState<
        FormBuilderFields[]
    >([]);
    const [checkboxes, setCheckboxes] = useState<number[]>([]);
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const [files, setFiles] = useState<{ [fieldId: string]: File }>({});
    const [isSubmitController, setIsSubmitController] =
        useState<boolean>(false);
    const [schema, setSchema] = useState(yup.object({}));

    const togglePasswordVisibility = fieldName => {
        setPasswordVisibility(prevState => ({
            ...prevState,
            [fieldName]: !prevState[fieldName]
        }));
    };

    /**
     * @description
     * This effect create the schema to validate the form, based on the formStructure
     */
    useEffect(() => {
        const showDepends = item => {
            return item.fieldNameId === initialValues[item.depends];
        };

        const save = formStructure.filter(function (item) {
            return item.depends === undefined || showDepends(item);
        });

        setNewFormStructure(save);
    }, [formStructure, initialValues]);

    useEffect(() => {
        let newSchema = {};
        if (newFormStructure.length > 0) {
            for (let i = 0; i < newFormStructure.length; i++) {
                const item = newFormStructure[i];
                const {
                    validations: { type, required, min, max, email }
                } = item;
                const obj = {};
                if (required.state) {
                    if (type === 'string') {
                        obj[item.fieldNameId] = yup
                            .string()
                            .required(required.message);
                        min &&
                            (obj[item.fieldNameId] = obj[item.fieldNameId].min(
                                min.length,
                                min.message
                            ));
                        max &&
                            (obj[item.fieldNameId] = obj[item.fieldNameId].max(
                                max.length,
                                max.message
                            ));
                        email &&
                            (obj[item.fieldNameId] = obj[
                                item.fieldNameId
                            ].email(email.message));
                    } else {
                        obj[item.fieldNameId] = yup.number().nullable();
                    }
                }
                newSchema = { ...newSchema, ...obj };
            }
            setReady(true);
        }
        setSchema(yup.object(newSchema));
    }, [newFormStructure]);

    /**
     * @description
     * This function send the form data to the parent component
     * @function Submit
     * @param dataSend
     */
    const Submit = async (dataSend: any) => {
        const dataSendCopy = { ...dataSend };
        for (const [key, value] of Object.entries(files)) {
            dataSendCopy[key] = value;
        }

        setIsSubmitController(true);
        try {
            await buttons?.ok.controller(dataSendCopy);
            CleanForm();
            setFiles({});
            setSendForm(true);
        } catch {
            console.log('Error with ok controller');
        }
        setIsSubmitController(false);
    };

    useEffect(() => {
        if (sendForm && onSuccessCleanForm) {
            CleanForm();
        }
    }, [sendForm, onSuccessCleanForm]);

    /**
     * @description
     * This function send the cancel function to the parent component to set focus in element and clear form
     * @function Cancel
     * @param OnCancel
     */
    const Cancel = (OnCancel: any) => {
        OnCancel();
        buttons?.cancel && buttons?.cancel.controller(focusOn);
        CleanForm();
    };

    const onHandleChange = (item, event) => {
        const newFiles = { ...files };
        const restructured: FormBuilderFields[] = [];

        const { elements } = item;
        const defValues = item.deffaultValues;
        let selected;
        if (defValues === undefined) {
            selected = elements.find(
                sel => sel.id === event.target.value
            )?.name;
        } else {
            const isTrueSet = event.target.value === 'true';
            selected = elements.find(sel => sel.id === isTrueSet)?.id;
        }

        formStructure.forEach(formItem => {
            if (!formItem.depends) {
                // always show non-dependant inputs
                restructured.push(formItem);
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete newFiles[formItem.fieldNameId];
                return;
            }

            if (formItem.dependsValues?.includes(selected)) {
                // show input that just changed
                restructured.push(formItem);
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete newFiles[formItem.fieldNameId];
                return;
            }

            if (
                newFormStructure.includes(formItem) &&
                !formItem.depends?.includes(item.fieldNameId)
            ) {
                // show if it was before and doesnt depend on the type just changed
                restructured.push(formItem);
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete newFiles[formItem.fieldNameId];
            }
        });

        setFiles(newFiles);
        setNewFormStructure(restructured);

        if (item.fieldNameId === 'property' && event.target.value !== '') {
            const units = elements.find(
                sel => sel.id === event.target.value
            )?.propertyUnits;
            if (unitsOnSelect) {
                unitsOnSelect(units);
            }
        }
        if (
            item.fieldNameId === 'client_business' &&
            event.target.value !== ''
        ) {
            const clientBusiness =
                elements.find(sel => sel.id === event.target.value)
                    ?.clientBusiness || [];
            const propertyMap = new Map();
            const gestorMap = new Map();
            clientBusiness.forEach(item => {
                const { property, propertyUnit, gestor } = item;

                if (property) {
                    if (!propertyMap.has(property.id)) {
                        propertyMap.set(property.id, {
                            ...property,
                            propertyUnits: []
                        });
                    }
                    if (propertyUnit) {
                        propertyMap
                            .get(property.id)
                            .propertyUnits.push(propertyUnit);
                    }
                }

                if (gestor) {
                    if (!gestorMap.has(gestor.id)) {
                        gestorMap.set(gestor.id, {
                            ...gestor
                        });
                    }
                }
            });
            const uniquePropertyList = Array.from(propertyMap.values());
            const uniqueGestorList = Array.from(gestorMap.values());

            if (propertiesOnSelect) {
                propertiesOnSelect({
                    properties: uniquePropertyList,
                    gestors: uniqueGestorList
                });
            }
        }
    };

    const onHandleFile = (item, event) => {
        files[item.fieldNameId] = event.target.files;
        setFiles({ ...files });
    };

    const checkboxChange = event => {
        const checkboxValue = parseInt(event.target.value, 10);
        const updatedCheckboxes = [...checkboxes];
        if (event.target.checked) {
            updatedCheckboxes.push(checkboxValue);
        } else {
            const index = updatedCheckboxes.indexOf(checkboxValue);
            if (index !== -1) {
                updatedCheckboxes.splice(index, 1);
            }
        }
        setCheckboxes(updatedCheckboxes);
    };

    const RenderControls = (item, handleChange, values, errors) => {
        let options: SelectProps['options'];
        switch (item.fieldType) {
            case 'select multiple':
                options = [];
                for (const element of item.elements) {
                    options.push({
                        value: element.id,
                        label: element.name
                    });
                }
                return (
                    <ConfigProvider
                        theme={{
                            components: {
                                Cascader: {
                                    colorBgContainer: '#f3f2f5',
                                    colorBgContainerDisabled: '#f3f2f5',
                                    controlItemBgHover: '#f3f2f5',
                                    colorBgElevated: '#f3f2f5'
                                }
                            }
                        }}
                    >
                        <Cascader
                            className='subtitle grey'
                            size='large'
                            style={{ width: '100%' }}
                            options={options}
                            onChange={selectedValues => {
                                handleChange({
                                    target: {
                                        name: item.fieldNameId,
                                        value: selectedValues.flat()
                                    }
                                });
                            }}
                            multiple
                            maxTagCount='responsive'
                            placeholder={item.fieldPlaceholder}
                        />
                    </ConfigProvider>
                );
            case 'select':
                return (
                    <select
                        id={item.fieldNameId}
                        required={
                            item.required ? item.required : defaults.required
                        }
                        onChange={e => {
                            onHandleChange(item, e);
                            handleChange(e);
                        }}
                        name={item.fieldNameId}
                        value={values[item.fieldNameId] || ''}
                        className={
                            errors[item.fieldNameId]
                                ? 'form-control invalid subtitle'
                                : config?.editButton
                                ? 'form-control preview subtitle'
                                : item.editable === false
                                ? 'form-control preview subtitle'
                                : 'form-control subtitle'
                        }
                        ref={item.isFocusInClean ? focusOn : null}
                        disabled={item.disabled || config?.editButton}
                    >
                        <option key='0' value=''>
                            Seleccione una opción
                        </option>
                        {item.elements.map((element: any) => {
                            return (
                                <option
                                    key={element.id}
                                    value={element.id || ''}
                                >
                                    {element.name
                                        ? element.name
                                        : element.description}
                                </option>
                            );
                        })}
                    </select>
                );
            case 'boolean':
                return (
                    <div className='form-radio-buttons'>
                        {item.elements.map((element: any) => (
                            <label
                                key={element.id}
                                className='form-radio-buttons__item subtitle'
                            >
                                {config?.editButton ? (
                                    <input
                                        id={item.fieldNameId}
                                        className='form-radio-buttons__item__btn'
                                        type='radio'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        onChange={handleChange}
                                        name={item.fieldNameId}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                        checked={
                                            values[item.fieldNameId] ===
                                            element.id
                                        }
                                    />
                                ) : (
                                    <input
                                        className='form-radio-buttons__item__btn'
                                        type='radio'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        onChange={handleChange}
                                        name={item.fieldNameId}
                                        value={element.name || ''}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                    />
                                )}
                                <div className='form-radio-buttons__item__name'>
                                    {element.name}
                                </div>
                            </label>
                        ))}
                    </div>
                );
            case 'checkbox':
                return (
                    <div className='form-checkboxes'>
                        {item.elements.map((element: any) => (
                            <label
                                key={element.id}
                                className='form-radio-buttons__item subtitle'
                            >
                                {config?.editButton ? (
                                    <input
                                        id={item.fieldNameId}
                                        className='form-radio-buttons__item__btn'
                                        type='checkbox'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        name={item.fieldNameId}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                        checked={values[
                                            item.fieldNameId
                                        ].includes(element.id)}
                                    />
                                ) : (
                                    <input
                                        className='form-radio-buttons__item__btn'
                                        type='checkbox'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        onChange={e => {
                                            checkboxChange(e);
                                            handleChange(e);
                                        }}
                                        name={item.fieldNameId}
                                        value={element.id || ''}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                    />
                                )}
                                <div className='form-radio-buttons__item__name'>
                                    {element.name}
                                </div>
                            </label>
                        ))}
                    </div>
                );
            case 'password':
                return (
                    <div
                        className={
                            errors[item.fieldNameId]
                                ? 'form-control invalid subtitle'
                                : config?.editButton
                                ? 'form-control preview subtitle'
                                : 'form-control subtitle'
                        }
                    >
                        <input
                            id={item.fieldNameId}
                            className='form-control__input'
                            required={
                                item.required
                                    ? item.required
                                    : defaults.required
                            }
                            onChange={handleChange}
                            autoComplete={
                                item.autoComplete
                                    ? item.autoComplete
                                    : defaults.autocomplete
                            }
                            placeholder={
                                item.fieldPlaceholder
                                    ? item.fieldPlaceholder
                                    : item.label
                            }
                            type={
                                passwordVisibility[item.fieldNameId]
                                    ? 'text'
                                    : item.fieldType
                            }
                            name={item.fieldNameId}
                            value={values[item.fieldNameId] || ''}
                            ref={item.isFocusInClean ? focusOn : null}
                            disabled={item.disabled || config?.editButton}
                        />
                        <button
                            type='button'
                            onClick={() =>
                                togglePasswordVisibility(item.fieldNameId)
                            }
                            className='eye-password'
                        >
                            <EyeOutlined rev={''} />
                        </button>
                    </div>
                );
            case 'number':
                return (
                    <input
                        id={item.fieldNameId}
                        required={
                            item.required ? item.required : defaults.required
                        }
                        onChange={handleChange}
                        autoComplete={
                            item.autoComplete
                                ? item.autoComplete
                                : defaults.autocomplete
                        }
                        placeholder={
                            item.fieldPlaceholder
                                ? item.fieldPlaceholder
                                : item.label
                        }
                        type={item.fieldType ? item.fieldType : 'text'}
                        min='0.01'
                        name={item.fieldNameId}
                        value={values[item.fieldNameId] || ''}
                        className={
                            errors[item.fieldNameId]
                                ? 'form-control invalid subtitle'
                                : config?.editButton
                                ? 'form-control preview subtitle'
                                : 'form-control subtitle'
                        }
                        ref={item.isFocusInClean ? focusOn : null}
                        disabled={item.disabled || config?.editButton}
                    />
                );
            case 'file':
                // if user can't edit it, then show its url (its value)
                if (item.disabled || config?.editButton || isSubmitController) {
                    return (
                        <input
                            id={item.fieldNameId}
                            required={
                                item.required
                                    ? item.required
                                    : defaults.required
                            }
                            onChange={handleChange}
                            autoComplete={
                                item.autoComplete
                                    ? item.autoComplete
                                    : defaults.autocomplete
                            }
                            placeholder={
                                item.fieldPlaceholder
                                    ? item.fieldPlaceholder
                                    : item.label
                            }
                            type='text'
                            name={item.fieldNameId}
                            value={values[item.fieldNameId] || ''}
                            className={
                                errors[item.fieldNameId]
                                    ? 'form-control invalid subtitle'
                                    : config?.editButton
                                    ? 'form-control preview subtitle'
                                    : 'form-control subtitle'
                            }
                            ref={item.isFocusInClean ? focusOn : null}
                            disabled={item.disabled || config?.editButton}
                        />
                    );
                }

                return (
                    <input
                        required={item.required ?? defaults.required}
                        onChange={e => {
                            onHandleFile(item, e);
                            handleChange(e);
                        }}
                        autoComplete={
                            item.autoComplete ?? defaults.autocomplete
                        }
                        placeholder={item.fieldPlaceholder ?? item.label}
                        type={item.fieldType ?? 'text'}
                        name={item.fieldNameId}
                        // when in edit mode, its initial value has to be ''.
                        value={
                            files[item.fieldNameId]
                                ? values[item.fieldNameId]
                                : ''
                        }
                        className={
                            errors[item.fieldNameId]
                                ? 'form-control invalid subtitle'
                                : config?.editButton
                                ? 'form-control preview subtitle'
                                : 'form-control subtitle'
                        }
                        ref={item.isFocusInClean ? focusOn : null}
                        disabled={item.disabled || config?.editButton}
                    />
                );
            default:
                return (
                    <input
                        id={item.fieldNameId}
                        required={
                            item.required ? item.required : defaults.required
                        }
                        onChange={handleChange}
                        autoComplete={
                            item.autoComplete
                                ? item.autoComplete
                                : defaults.autocomplete
                        }
                        placeholder={
                            item.fieldPlaceholder
                                ? item.fieldPlaceholder
                                : item.label
                        }
                        type={item.fieldType ? item.fieldType : 'text'}
                        name={item.fieldNameId}
                        value={values[item.fieldNameId] || ''}
                        className={
                            errors[item.fieldNameId]
                                ? 'form-control invalid subtitle'
                                : config?.editButton
                                ? 'form-control preview subtitle'
                                : 'form-control subtitle'
                        }
                        ref={item.isFocusInClean ? focusOn : null}
                        disabled={item.disabled || config?.editButton}
                    />
                );
        }
    };

    return ready ? (
        <div className='formbuilder subsubtitle' style={styles}>
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={(dataForm: any) => {
                    Submit(dataForm);
                }}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    resetForm,
                    isSubmitting
                }: UserFormikInterface) => (
                    //  eslint-disable-next-line no-sequences
                    (CleanForm = resetForm),
                    (
                        <Form
                            className='formbuilder__form subtitle'
                            noValidate
                            onFinish={dataForm => {
                                handleSubmit(dataForm);
                            }}
                        >
                            {newFormStructure.map(
                                (item: FormBuilderFields, index: number) => (
                                    //  item.depends ? item.dependsShow &&
                                    <div
                                        key={`form_item-${index}`}
                                        className={
                                            item.show !== undefined &&
                                            !item.show &&
                                            item.disabled
                                                ? 'noshowelement disabled mb-3 subtitle'
                                                : 'enabled mb-3 subtitle'
                                        }
                                    >
                                        {!config?.noLabels && (
                                            <label
                                                className={
                                                    item.disabled
                                                        ? 'disabled subtitle'
                                                        : 'enabled subtitle'
                                                }
                                                htmlFor={item.fieldNameId}
                                            >
                                                {item.label}
                                            </label>
                                        )}
                                        {RenderControls(
                                            item,
                                            handleChange,
                                            values,
                                            errors
                                        )}
                                        <div className='invalid-feedback'>
                                            {errors[item.fieldNameId]}
                                        </div>
                                    </div>
                                )
                            )}
                            <Wrapper
                                className={
                                    buttons.buttonsWrapperClass
                                        ? buttons.buttonsWrapperClass
                                        : defaults.buttons.buttonsWrapperClass
                                }
                            >
                                {!config?.noClearButton && (
                                    <Button
                                        className={`${
                                            buttons?.cancel?.class ??
                                            defaults.buttons.cancel?.class
                                        } subtitle`}
                                        onClick={() => Cancel(resetForm)}
                                    >
                                        {buttons?.cancel && buttons?.cancel.text
                                            ? buttons?.cancel.text
                                            : defaults.buttons.cancel?.text}
                                    </Button>
                                )}
                                {!config?.editButton && (
                                    <Button
                                        className={`${
                                            buttons?.ok?.class ??
                                            defaults.buttons.ok?.class
                                        } subtitle`}
                                        htmlType='submit'
                                    >
                                        {buttons?.ok.text
                                            ? buttons?.ok.text
                                            : defaults.buttons.ok.text}
                                    </Button>
                                )}
                            </Wrapper>
                        </Form>
                    )
                )}
            </Formik>
        </div>
    ) : (
        <>
            <span>rdy formik {ready.toString()} </span>
        </>
    );
};

export default FormBuilder;
